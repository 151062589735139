<template>
  <section
    v-if="products.length"
    class="product-relations"
  >
    <h3 class="s5 bold title-separator">
      <span>{{ title }}</span>
    </h3>
    <Slider
      v-if="!isLoading"
      id="slider_suggestions"
      pagination-small
    >
      <template #slider-items>
        <SliderCard
          v-for="i in totalPages"
          :key="i"
        >
          <template #custom>
            <div
              v-for="n in 4"
              :key="n"
            >
              <ProductItem
                v-if="products[4*(i-1)+n-1]"
                v-model="products[4*(i-1)+n-1]"
                small
              />
            </div>
          </template>
        </SliderCard>
      </template>
    </Slider>
  </section>
</template>

<script>
import { Slider, SliderCard } from "@lde/core_lde_vue";

import ProductItem from "@/components/products/ProductItem.vue";

/**
 * Affiche des produits en relation avec le produit sélectionné.
 */
export default {
  name: "ProductRelations",
  components: {
    ProductItem,
    Slider,
    SliderCard,
  },
  props: {
    /**
     * Produit de référence.
     */
    products: {
      type: Array,
      required: true,
    },
    /**
     * Définit si les produits sont en train de charger.
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Titre de la section placé au-dessus du slider.
     */
    title: {
      type: String,
      default() { return this.$t("produit.relation-ressource"); },
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.products.length / 4);
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

.product-relations {
  h3 {
    margin-bottom: var.$space-small;
    color: var.$blue;
  }

  #slider_suggestions { height: initial; }

  .shadow-card,
  .slider-card { box-shadow: none; }

  .slider-items {
    height: 47.8rem;
    overflow: visible;
  }

  .slider-card {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var.$space-medium;
    background: none;
    > div { grid-column: span 3; }
  }

  .product-item { height: 47.8rem; }
}
</style>
