import { copyToClipboard } from "@lde/core_lde_vue";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      product: null,
      offersToAdd: null,
    };
  },
  computed: {
    ...mapGetters(["panier", "isFetchingActiveLists", "activeLists"]),
  },
  created() {
    // On a besoin de la méthode dans le tooltip, qui ne permet pas de l'appeler avec this
    window.openSelectionListModal = this.openSelectionListModal.bind(this);
  },
  mounted() {
    this.fetchProduct().catch((err) => {
      if (err.response?.status === 404) {
        if (this.idOrganisme) {
          // voir frontend/src/components/modals/ModalVerifyAccount.vue
          localStorage.removeItem("sessionFondsPropresAccept");
          Api().post("/switch/", { organisme: this.idOrganisme }).then((res) => {
            this.$store.commit("setOrganismeActuel", res.data);
            // Reload pour redéclencher le identify et éviter d'éventuels soucis
            window.location.reload();
          });
        }
        this.$store.commit("setErrorStatus", err.response.status);
      }
    });

    this.idOrganisme = this.$route.query.id_organisme;
    if (this.idOrganisme) {
      // On supprime l'id organisme de l'URL
      this.$router.push({ query: { id_organisme: undefined } });
    }
  },
  methods: {
    copyToClipboard,
    /**
     * Vérifie si on doit afficher la modale de sélection de liste, la modale de création de liste,
     * ou si on peut ajouter le produit directement dans la liste sans afficher de modale.
     * @param {Object} offer Offre sélectionnée.
     */
    checkToList({ id, quantite }) {
      this.offersToAdd = [{
        id_offre: id,
        quantite,
        libelle: this.product.libelle,
      }];

      if (this.offersToAdd.some((offre) => !offre.quantite)) {
        // Si la quantité est invalide
        this.$toast.error({ title: this.$t("toast.selection-quantite-superieur-a-x", { quantite: 0 }) });
      } else if (this.activeLists.length) {
        // Si l'utilisateur a déjà au moins une liste, l'offre sélectionnée est stockée dans le store
        // avant d'afficher la modale de sélection de liste
        this.$store.commit("setSelectedLines", this.offersToAdd);
        this.$modal.show("modal_listes_selection");
      } else {
        // Si on n'a aucune liste, l'offre sélectionnée est stockée dans le store
        // avant d'afficher la modale de création de liste
        this.$store.commit("setSelectedLines", this.offersToAdd);
        this.$modal.show("modal_listes_creation");
      }
    },
    /**
     * Ouvre la modale de sélection de liste.
     */
    openSelectionListModal() {
      this.$modal.show("modal_listes_selection");
    },
  },
};
